export const environment = {
  production: true,
  apiurl: 'https://soap.omnicms3.nl:9003/api/v1/',
  mock: false,
  config: {
    product: "Mijn NovaPas",
    customer_fullname: "Nova College",
    customer_shortname: "novacollege",
    url: "",
    phone: "",
    email: "",
    MFAemail: false,
    MFAsms: false,
    logintype : "surf",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: { issuer: "https://connect.surfconext.nl"
                , clientId : "novacollege.mijnomnipas.nl"
                , redirectUri: "https://novacollege.mijnomnipas.nl/site/surflogin"
                , silentRefreshRedirectUri: "https://novacollege.mijnomnipas.nl/site/silent-refresh.html"
                , logoutUrl: "https://engine.surfconext.nl/logout"
                , scope: "openid profile email"
                , loginItem: "uids"
                , oidc: true
                , skipPage: true
                , secret: "x50QeNOFBilrlS7kuaKQ"
                },
    adfsConfig: {},
    ingenico: {
      url: "https://secure.ogone.com/ncol/prod/orderstandard.asp",
      orderid: "",
      pspid: "Novacollege",
      sha_in_key: "FSS3pCl*2mrN2fj#?Tp",
      sha_out_key: "to8c.5C{lbcaX3VJ[vc",
      currency: "EUR",
      bgcolor: "#F3F3F3",
      txtcolor: "#004C93",
      tblbgcolor: "#FFFFFF",
      tbltxtcolor: "#666666",
      buttonbgcolor: "#004C93",
      buttontxtcolor: "#FFFFFF",
      fonttype: "Verdana",
      pm: "iDEAL",
      homeurl: "https://novacollege.mijnomnipas.nl/site/payment",
      accepturl: "https://novacollege.mijnomnipas.nl/site/payment/success",
      declineurl: "https://novacollege.mijnomnipas.nl/site/payment/decline",
      exceptionurl: "https://novacollege.mijnomnipas.nl/site/payment/fail",
      cancelurl: "https://novacollege.mijnomnipas.nl/site/payment/cancel"
    },
    cardprices: { "Student": 1250, "Employee": 0, "External employee": 0, "default": 1250 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "novapas"
      },
      {
        id: 2,
        LanguageCode: "en",
        LanguageName: "English",
        Card: "nova card"
      },
    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: true,
    chargingcard: false,
    digitalcard: false,
    uploadphoto: false,
    webcam: false,
    licenseplate: false,
    nameoncard: false,
    cardpreview: true,
    cardaslink: false,
    cardresult: false,
    cardactivate: false,
    cardblock: true,
    cardhist: true,
    carddeblock: true,
    cardrequest: true,
    logoutbutton: false,
    requestnewpassword: false,
    publishPermission: true,
    scrollButtons: true,
    breadcrumbs: false,
    versioninfooter: false,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: false,
    firstnameonwelcome: true,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: false,
    langimage : false,
    maintainance: false,
    timeout: 180,
  },
  ssl: true
};
